<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
    <g id="Play_Button" data-name="Play Button" transform="translate(-765 -562)" opacity="0.6">
      <g id="Ellipse_66" data-name="Ellipse 66" transform="translate(765 562)" fill="none" :stroke="color()"
         stroke-width="4">
        <circle cx="30" cy="30" r="30" stroke="none"/>
        <circle cx="30" cy="30" r="28" fill="none"/>
      </g>
      <path id="Polygon_3" data-name="Polygon 3" d="M12,0,24,20H0Z" transform="translate(808 580) rotate(90)"
            :fill="color()"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconVideoPlay",
  props: {
    hover: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    color: function () {
      return (this.hover) ? "#FFF" : "#999"
    }
  }
}
</script>

<style lang="scss" scoped>
svg {
  cursor: pointer;
  transition: all .3s ease-in-out;
  z-index: 1;
}
</style>
<template>
  <div class="single-image" :style="{
    backgroundImage: `url(${data.item_details[0].file})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  }">
  </div>
</template>

<script>
export default {
  name: "single-image",
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.single-image {
  align-items: center;
  height: 100%;
  justify-content: center;
  display: flex;
}
</style>
<template>
  <div class="pdfs h-100">
    <BSModal id="pdfs-modal" :data="selectedItem"/>

    <div class="pdfs-wrapper d-flex flex-wrap justify-content-center align-items-center h-100">
      <div v-for="item in data.item_details"
           :key="item.id"
           class="card"
           data-bs-target="#pdfs-modal"
           data-bs-toggle="modal"
           @click="selectItem(item)"
           @mouseenter="setHover(item.id)"
           @mouseleave="setHover(null)">
        <div class="card-img-top">
          <IconPdf :hover="(hover === item.id)" class="icon-pdf"/>
          <img :src="item.thumbnail" alt="" class="img-fluid pdf-thumbnail">
        </div>
        <div class="card-body">
          {{ item.name }}
        </div>
      </div>
    </div>
    <!--    <button data-bs-toggle="modal" data-bs-target="#pdfs-modal">Toggle modal</button>-->
  </div>
</template>

<script>
import BSModal from "@/components/common/BS-Modal";
import IconPdf from "@/components/common/icons/IconPdf";

export default {
  name: "PDFS",
  components: {IconPdf, BSModal},
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selectedItem: null,
      hover: null,
    }
  },
  methods: {
    setHover: function (id) {
      this.hover = id
    },
    selectItem: function (item) {
      this.selectedItem = item
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/cards";

.pdfs {
  padding: 1rem;

  .pdfs-wrapper {
    grid-template-columns: repeat(5, 19%);
    column-gap: 1%;
    row-gap: 2%;
  }
}
</style>z
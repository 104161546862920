<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="20"
       height="20"
       viewBox="0 0 20 20"
       @mouseover="hover = true"
       @mouseout="hover = false"
  >
    <g transform="translate(-2 -2)" opacity="0.602">
      <path id="XMLID_1120_"
            d="M22,2.909V7.455a.909.909,0,0,1-1.818,0V5.114l-5.273,5.273a.908.908,0,0,1-1.273-1.3l5.25-5.273H16.545a.909.909,0,1,1,0-1.818h4.545a.825.825,0,0,1,.636.273A.825.825,0,0,1,22,2.909Zm-.909,12.727a.912.912,0,0,0-.909.909v2.341L15,13.75a.932.932,0,0,0-1.341,1.3L18.8,20.182H16.455a.909.909,0,1,0,0,1.818H21a.925.925,0,0,0,.682-.273A.955.955,0,0,0,22,21.091V16.545A.912.912,0,0,0,21.091,15.636ZM9,13.7,3.818,18.886V16.545a.909.909,0,1,0-1.818,0v4.545a.825.825,0,0,0,.273.636A.91.91,0,0,0,2.932,22H7.477a.909.909,0,1,0,0-1.818H5.114L10.3,15a.926.926,0,0,0,0-1.3A.905.905,0,0,0,9,13.7Zm-3.886-9.8H7.455a.909.909,0,0,0,0-1.818H2.909a.825.825,0,0,0-.636.273A.825.825,0,0,0,2,3V7.545a.909.909,0,0,0,1.818,0V5.182l5.25,5.25a.908.908,0,1,0,1.273-1.3L5.114,3.909Z"
            :fill="color"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconExpand",
  data() {
    return {
      hover: false
    }
  },
  computed: {
    color: function () {
      return (this.hover) ? '#FFF' : '#9A9A9A'
    }
  }
}

</script>

<style lang="scss" scoped>
svg {
  cursor: pointer;
}
</style>
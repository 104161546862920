export const TYPES = {
    BUTTON_LINK: 'button_link',
    GALLERY_SLIDER: 'gallery_slider',
    I_FRAME: 'hotspot_show_iframe',
    PDFS: "pdf",
    SINGLE_IMAGE: "single_image",
    SINGLE_PDF: "single_pdf",
    SINGLE_VIDEO: "single_video",
    VIDEOS: "videos",
}

export default {
    TYPES
}
<template>
  <svg id="Pause_Icon" data-name="Pause Icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
       viewBox="0 0 20 20" @mouseover="color = '#FFF'" @mouseout="color = '#9A9A9A'">
    <g id="Pause_Icon-2" data-name="Pause Icon" transform="translate(-1257 -362)" opacity="0.602">
      <g id="Rectangle_9" data-name="Rectangle 9" transform="translate(1257 362)" fill="none" :stroke="color"
         stroke-width="2">
        <rect width="8" height="20" rx="1" stroke="none"/>
        <rect x="1" y="1" width="6" height="18" fill="none"/>
      </g>
      <g id="Rectangle_10" data-name="Rectangle 10" transform="translate(1269 362)" fill="none" :stroke="color"
         stroke-width="2">
        <rect width="8" height="20" rx="1" stroke="none"/>
        <rect x="1" y="1" width="6" height="18" fill="none"/>
      </g>
    </g>
  </svg>

</template>

<script>
export default {
  name: "IconPause",
  data() {
    return {
      color: '#9A9A9A'
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
<template>
  <div id="side-menu" class="h-100">
    <div class="logo d-flex justify-content-center align-items-center">
      <img :src="hotspot.image" alt="" class="img-fluid">
    </div>
    <div class="menu">
      <div class="menu-item" v-for="(item, i) in menu_items" :key="i">
        <div :class="['menu-item-link', {'active': activeItem === i}]" @click="setActiveItem(item, i)">
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="room-abbreviation">{{ (room) ? room.abbreviation : '' }}</div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: "SideMenu",
  data() {
    return {
      activeItem: 0
    }
  },
  methods: {
    setActiveItem: function (item, i) {
      this.activeItem = i
      this.$emit('change-tab', item)
    }
  },
  computed: {
    ...mapState({
      hotspot: state => state.hotspot.data.hotspot_content[0],
      room: state => state.hotspot.data.room,
      menu_items: state => state.hotspot.data.hotspot_content[0].menu_items
    }),
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/colors";

#side-menu {
  background-color: $bg-transparent;
  display: grid;
  grid-template-rows: 80px auto;

  .logo {
    background-color: $bg-dark;
    height: 80px;
  }

  .menu {
    padding-top: 60px;
    overflow-y: scroll;

    .menu-item {
      .menu-item-link {
        cursor: pointer;
        color: $color-white;
        display: inline-block;
        font-size: 14px;
        font-weight: bold;
        padding-bottom: 16px;
        padding-left: 40px;

        &.active {
          color: $color-active-red;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }

  }

  .room-abbreviation {
    background-color: $color-red;
    bottom: 0;
    color: $color-white;
    padding: 14px;
    position: absolute;
    right: 0;
  }
}
</style>
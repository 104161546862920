<template>
  <div class="button-link d-flex justify-content-end align-items-center w-100 h-100" :style="{
    backgroundImage: `url(${data.item_details[0].background_image})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    }">
    <div class="content">
      <h1>{{ data.item_details[0].button_title_text }}</h1>
      <base-button @click="openUrl" :text="data.item_details[0].button_text" />
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/common/buttons/BaseButton";

export default {
  name: "button-link",
  components: {BaseButton},
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    openUrl: function() {
      const link = this.data.item_details[0].link
      window.open(link)
    }
  }
}
</script>

<style lang="scss" scoped>
 .button-link {
   position: relative;
   .content {
     margin-right: 40px;
     text-align: right;
     z-index: 1;
    h1 {
      color: #FFFFFF;
      font-size: 40px;
      font-weight: bold;
      margin-bottom: 40px;
      text-align: right;
    }
     button {}
   }
   &:after {
     background: linear-gradient(-90deg, #000, transparent);
     bottom: 0;
     content: '';
     position: absolute;
     right: 0;
     top: 0;
     width: 50%;
   }
 }
</style>
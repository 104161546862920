<template>
  <div class="videos h-100">

    <div
        :class="['videos-list d-flex flex-wrap align-items-center h-100', {'justify-content-center': data.item_details.length < 5}]">
      <div v-for="item in data.item_details"
           :key="item.id"
           class="card"
           @click="setSelectedItem(item)"
           @mouseenter="setHover(item.id)"
           @mouseleave="setHover(null)">
        <div class="card-img-top">
          <img :src="item.thumbnail" alt="" class="img-fluid video-thumbnail">
          <IconVideoPlay :hover="hover === item.id" class="icon-play"/>
        </div>
        <div class="card-body">
          {{ item.name }}
        </div>
      </div>
    </div>

    <Transition name="fade" mode="in-out">
    <div class="player-container" v-if="selectedItem">
      <video-player :item="selectedItem" :key="selectedItem.id" :show-back-button="true" :allow-fullscreen="true"/>
    </div>
    </Transition>

  </div>
</template>

<script>
import IconVideoPlay from "@/components/common/icons/IconVideoPlay";
import VideoPlayer from "@/components/common/VideoPlayer";
import {inject, ref,} from 'vue'

export default {
  name: "videos",
  components: {IconVideoPlay, VideoPlayer},
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup() {
    const emitter = inject('$BUS')
    let selectedItem = ref(null)
    let hover = ref(null)


    const setHover = (id) => {
      hover.value = id
    }

    const setSelectedItem = item => {
      selectedItem.value = item
    }

    emitter.on('close-video', () => {
      selectedItem.value = null
    })

    return {
      selectedItem, hover, setHover, setSelectedItem
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/cards";
@import "../../assets/scss/transitions";

.videos {
  padding: 24px;
  position: relative;

  .videos-list {
    column-gap: 1%;
    row-gap: 2%;
  }

  .player-container {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }

}
</style>
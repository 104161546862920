<template>
  <svg id="Arrow_Left" data-name="Arrow Left" xmlns="http://www.w3.org/2000/svg" width="48" height="48"
       viewBox="0 0 48 48">
    <g id="Arrow_Left-2" data-name="Arrow Left" opacity="0.602">
      <g id="Ellipse_2" data-name="Ellipse 2" fill="none" :stroke="color" stroke-width="4">
        <circle cx="24" cy="24" r="24" stroke="none"/>
        <circle cx="24" cy="24" r="23" fill="none"/>
      </g>
      <path id="Arrow"
            d="M10.539,23.878,2.712,15.136a1.352,1.352,0,0,1-.264-1.415,1.5,1.5,0,0,1,.264-.427l7.838-8.73a.948.948,0,0,1,.738-.349.964.964,0,0,1,.543.157,1.12,1.12,0,0,1,.38.433,1.323,1.323,0,0,1,.142.614,1.3,1.3,0,0,1-.316.867l-7.648,8.5V13.643l7.648,8.513a1.3,1.3,0,0,1,.316.867,1.323,1.323,0,0,1-.142.614,1.074,1.074,0,0,1-.38.427.993.993,0,0,1-.543.151.966.966,0,0,1-.406-.084A1.1,1.1,0,0,1,10.539,23.878Z"
            transform="translate(16.646 9.785)" :fill="color"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconCirclePrev",
  data() {
    return {
      color: '#FFF'
    }
  },
}
</script>

<style lang="scss" scoped>
svg {
  width: 30px;
}
</style>
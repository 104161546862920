<template>
  <div :id="id" class="modal fade">
    <div class="modal-dialog modal-fullscreen" style="width: 75%">
      <div class="modal-content">
        <div v-if="data" class="modal-body" style="padding: 0">
          <IconBack data-bs-dismiss="modal" @click="closeModal" />

          <div v-if="data.type === 'video'" class="video-container h-100">
            <video-player :item="data" :allow-fullscreen="true" :auto-play="true" :key="data.id" />
          </div>

          <div v-if="data.type === 'pdf'" class="pdf-container w-100 h-100">
            <iframe :src="data.file" class="pdf w-100 h-100"/>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Invoke using data-bs-target="#id" and data-bs-toggle="modal" on the element that acts as button
 */
import IconBack from "@/components/common/icons/IconBack";
import VideoPlayer from "@/components/common/VideoPlayer";

export default {
  name: "BS-Modal",
  components: {
    IconBack,
    VideoPlayer
  },
  props: {
    id: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      required: false
    }
  },
  methods: {
    closeModal: function() {
      this.$BUS.emit('close-modal')
    },
  }
}
</script>

<style lang="scss" scoped>
.modal {
  .modal-content {
    .modal-body {
      svg {
        left: 0;
        position: absolute;
        top: 0;
        z-index: 1;
      }
      .video-container {
        position: relative;
        video {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
        }
      }
    }
  }
}
</style>
<template>
  <div class="single-video h-100">
      <video-player :item="data.item_details[0]" :allow-fullscreen="true" :key="data.item_details[0].id"/>
  </div>
</template>

<script>
import VideoPlayer from "@/components/common/VideoPlayer.vue";

export default {
  name: 'videos',
  components: {VideoPlayer},
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.single-video {
  video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
</style>
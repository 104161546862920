<template>
  <loading v-if="loading"/>
  <div id="hotspot" v-else>
    <div :class="['left', {'full': activeComponent === 'hotspot_show_iframe'}]">
      <transition name="fade" mode="in-out">
        <component :is="activeComponent" :data="componentData"/>
      </transition>
    </div>

    <div v-if="activeComponent !== 'hotspot_show_iframe'" class="right">
      <SideMenu @change-tab="setActiveComponent"/>
    </div>
  </div>
</template>

<script>
import {TYPES} from "@/consts";

import {ref, onBeforeMount} from 'vue'
import {useStore} from 'vuex'
import {useRoute, useRouter} from 'vue-router'

import SideMenu from "@/components/SideMenu";

import ButtonLink from '@/components/templates/ButtonLink.vue';
import ExternalContent from "@/components/templates/ExternalContent.vue";
import GallerySlider from "@/components/templates/GallerySlider.vue";
import PDFS from "@/components/templates/PDFS.vue";
import SingleImage from "@/components/templates/SingleImage.vue";
import SinglePdf from "@/components/templates/SinglePdf.vue";
import SingleVideo from "@/components/templates/SingleVideo.vue";
import Videos from "@/components/templates/Videos.vue";
import Loading from "@/components/common/Loading";

export default {
  name: "Hotspot",
  components: {
    Loading,
    SideMenu,
    [TYPES.BUTTON_LINK]: ButtonLink,
    [TYPES.GALLERY_SLIDER]: GallerySlider,
    [TYPES.I_FRAME]: ExternalContent,
    [TYPES.PDFS]: PDFS,
    [TYPES.SINGLE_IMAGE]: SingleImage,
    [TYPES.SINGLE_PDF]: SinglePdf,
    [TYPES.SINGLE_VIDEO]: SingleVideo,
    [TYPES.VIDEOS]: Videos,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const activeComponent = ref(null)
    const componentData = ref(null)
    const loading = ref(false)
    const hotspot = ref(null)

    onBeforeMount(() => {
      loading.value = true
      const dispatch = async () => {
        try {
          const data = await store.dispatch('setHotspot', {id: route.params.id, code: route.params.code, q: route.query})

          componentData.value = data.hotspot_content[0].menu_items[0]
          if (data.hotspot_content[0].menu_items.length) {
            activeComponent.value = data.hotspot_content[0].menu_items[0].type.key
          }

          hotspot.value = data
          loading.value = false
        } catch (err) {
          console.log('err', err)
          console.log('err code', err.code)
          console.log('err message', err.message)
          if (err.code) {
            return router.replace(`/${err.code}`)
          }
          console.log('nope', err)
        }
      }
      dispatch()
    })

    const setActiveComponent = (item) => {
      activeComponent.value = item.type.key
      componentData.value = item
    }

    return {
      activeComponent,
      componentData,
      hotspot,
      loading,
      setActiveComponent
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/colors";
@import "../assets/scss/transitions";

#hotspot {
  height: 100%;

  .left {
    float: left;
    height: inherit;
    width: 75%;
    position: relative;

    &.full {
      width: 100%;
    }
  }

  .right {
    float: left;
    height: inherit;
    width: 25%;
  }
}
</style>
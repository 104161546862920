<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g id="Adobe_PDF_icon" transform="translate(-85.163 -88.96)" opacity="0.605">
      <path id="Adobe_PDF_icon-2" data-name="Adobe_PDF_icon"
            d="M123.753,116.243a3.557,3.557,0,0,1-1.394.251c-1.453,0-3.234-.683-5.753-1.784.973-.072,1.851-.108,2.648-.108a9.99,9.99,0,0,1,3.3.371c1.418.359,1.43,1.114,1.2,1.269Zm-25.2.228c.562-1.006,1.137-2.072,1.722-3.209a60.726,60.726,0,0,0,3.011-6.73,22.314,22.314,0,0,0,4.98,6.323c.246.216.5.419.785.635A64.333,64.333,0,0,0,98.548,116.47Zm4.664-26.212c.8,0,1.254,2.043,1.289,3.971a8.269,8.269,0,0,1-.949,4.263,21.081,21.081,0,0,1-.668-5.329S102.849,90.258,103.212,90.258ZM87.581,127.044a17.73,17.73,0,0,1,4.875-5.963c.164-.132.574-.527.949-.886C90.651,124.7,88.8,126.481,87.581,127.044ZM124.515,113.6c-.8-.8-2.578-1.221-5.273-1.257a43.321,43.321,0,0,0-6.339.467,16.352,16.352,0,0,1-2.941-2.072,22.075,22.075,0,0,1-5.3-8.418c.07-.311.141-.575.2-.85,0,0,1.266-7.364.926-9.855a2.712,2.712,0,0,0-.164-.707l-.105-.3a2.287,2.287,0,0,0-2.086-1.625l-.621-.02H102.8a2.35,2.35,0,0,0-2.4,1.538c-.773,2.91.023,7.245,1.465,12.861l-.375.922c-1.031,2.563-2.32,5.149-3.457,7.424l-.152.3c-1.2,2.395-2.285,4.431-3.269,6.155l-1.019.551c-.07.048-1.816.982-2.226,1.233-3.468,2.12-5.774,4.526-6.156,6.442a1.611,1.611,0,0,0,.587,1.748l.984.5a2.957,2.957,0,0,0,1.339.323c2.472,0,5.343-3.137,9.292-10.19a90.76,90.76,0,0,1,14.331-3.485c3.468,2,7.734,3.389,10.429,3.389a4.582,4.582,0,0,0,1.23-.144,2.025,2.025,0,0,0,1.219-.85,4.56,4.56,0,0,0,.48-3.042,2.041,2.041,0,0,0-.586-1.042Z"
            :fill="color()"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconPdf",
  props: {
    hover: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    color: function () {
      return (this.hover) ? "#C00" : "#999"
    }
  }
}
</script>

<style lang="scss" scoped>
#Adobe_PDF_icon {
  transition: all .3s ease-in-out;
}
</style>
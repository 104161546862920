<template>
  <div class="gallery-slider" ref="gallerySlider">

    <IconExpand class="icon-video-expand" :hover="hover" @click="expand()"/>

    <div class="ride-controls">
      <IconPause v-show="!paused" class="icon-pause" @click="pause()"/>
      <IconPlay v-show="paused" class="icon-play" @click="cycle()"/>
    </div>

    <div id="carousel"
         class="carousel slide"
         @mouseover="setHover(true)"
         @mouseout="setHover(false)">
      <div class="carousel-inner">
        <div v-for="(item,i) in data.item_details"
             :key="item.id"
             :class="['carousel-item', {'active': i === 0}]">

          <div v-if="item.type === 'image'"
               class="img-wrapper image"
               :style="{
                  backgroundImage: `url(${item.file})`,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
               }" />

          <video-player v-if="item.type === 'video'" :item="item" />

        </div>
      </div>

      <button class="carousel-control-prev" type="button">
        <IconCirclePrev class="carousel-control-prev-icon" aria-hidden="true" @click="prev()"/>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button">
        <IconCircleNext class="carousel-control-next-icon" aria-hidden="true" @click="next()"/>
        <span class="visually-hidden">Next</span>
      </button>

    </div>

  </div>
</template>

<script>
import {Carousel} from 'bootstrap';
import IconCircleNext from "@/components/common/icons/IconCircleNext";
import IconCirclePrev from "@/components/common/icons/IconCirclePrev";
import IconExpand from "@/components/common/icons/IconExpand";
import IconPause from "@/components/common/icons/IconPause";
import IconPlay from "@/components/common/icons/IconPlay";
import VideoPlayer from "@/components/common/VideoPlayer";
import {onMounted, ref, inject} from 'vue'

export default {
  name: "gallery-slider",
  components: {
    IconCircleNext,
    IconCirclePrev,
    IconExpand,
    IconPause,
    IconPlay,
    VideoPlayer,
  },
  props: {
    data: {
      type: Object,
      required: true
    },
  },
  setup() {
    const emitter = inject('$BUS')
    const fullScreen = ref(false)
    const gallery = ref(null)
    const hover = ref(false)
    const paused = ref(false)
    const gallerySlider = ref(null)

    onMounted(() => {
      gallery.value = new Carousel('#carousel', {
        interval: 10000,
        hover: false
      })
      gallery.value.cycle()
    })
    const setHover = (bool) => {
      hover.value = bool
    }

    /**
     * Handle fullscreen
     */
    const enterFullscreen = () => {
      if (gallerySlider.value.requestFullscreen) {
        gallerySlider.value.requestFullscreen()
      } else if (gallerySlider.value.webkitRequestFullscreen) {
        gallerySlider.value.webkitRequestFullscreen()
      }
    }

    const exitFullscreen = () => {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      }
    }

    const expand = () => {
      (fullScreen.value) ? exitFullscreen() : enterFullscreen()
      fullScreen.value = !fullScreen.value
    }

    /**
     * Gallery control methods
     */
    const cycle = () => {
      gallery.value.cycle()
      paused.value = false
    }

    const pause = () => {
      if (gallery.value) {
        gallery.value.pause()
      }
      paused.value = true
    }

    const next = () => {
      gallery.value.next()
      emitter.emit('change-slide')
    }

    const prev = () => {
      gallery.value.prev()
      emitter.emit('change-slide')
    }

    /**
     * Here we're listening to event emitted from the VideoPlayer.vue
     */
    emitter.on('playing', () => {
      pause()
    })

    emitter.on('pause', () => {
      pause()
    })

    emitter.on('ended', () => {
      cycle()
    })

    return {
      fullScreen,
      gallery,
      gallerySlider,
      hover,
      paused,
      cycle,
      expand,
      next,
      pause,
      prev,
      setHover,
    }
  }
}
</script>

<style lang="scss" scoped>
.gallery-slider {
  height: 100%;
  position: relative;
  width: 100%;

  [type=button]:not(:disabled),
  [type=reset]:not(:disabled),
  [type=submit]:not(:disabled),
  button:not(:disabled) {
    cursor: auto;
  }

  .icon-video-expand {
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 2;
  }

  .icon-video-play {
    cursor: pointer;
    height: 40px;
    left: 50%;
    position: absolute;
    top: calc(50% - 20px);
    transition: all 0.3s ease-in-out;
    z-index: 1;
  }

  .ride-controls {
    bottom: 16px;
    position: absolute;
    right: 16px;
    z-index: 2;

    .icon-pause, .icon-play {
      position: relative;
      bottom: 0;
      cursor: pointer;
      width: 30px;
    }
  }

  .carousel {
    height: inherit;
    width: inherit;

    .carousel-control-next,
    .carousel-control-prev {
      width: 60px;
      top: 50%;
      bottom: 50%;

      &:hover {
      }

      .carousel-control-next-icon,
      .carousel-control-prev-icon {
        background: unset;
        cursor: pointer;
        width: 40px;
        height: 40px;
      }
    }

    .carousel-icon {
      align-items: center;
      border-radius: 50%;
      border: 2px solid #FFF;
      cursor: pointer;
      display: inline-flex;
      height: 40px;
      justify-content: center;
      padding: 8px;
      width: 40px;
    }

    .carousel-inner {
      height: inherit;

      .carousel-item {
        height: 100%;

        .img-wrapper {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          &:before {
            content: '';
            padding-top: 57%;
          }

          .image {
            img {
              max-height: 100%;
            }
          }

        }
      }
    }
  }
}

</style>
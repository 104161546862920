<template>
  <svg id="Arrow_Right" data-name="Arrow Right" xmlns="http://www.w3.org/2000/svg" width="48" height="48"
       viewBox="0 0 48 48">
    <g id="Arrow_Right-2" data-name="Arrow Right" transform="translate(-1054 -464)" opacity="0.602">
      <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(1054 464)" fill="none" :stroke="color"
         stroke-width="4">
        <circle cx="24" cy="24" r="24" stroke="none"/>
        <circle cx="24" cy="24" r="23" fill="none"/>
      </g>
      <path id="Arrow"
            d="M4.168,23.878l7.827-8.742a1.352,1.352,0,0,0,.264-1.415,1.5,1.5,0,0,0-.264-.427L4.157,4.564a.948.948,0,0,0-.738-.349.964.964,0,0,0-.543.157A1.12,1.12,0,0,0,2.5,4.8a1.323,1.323,0,0,0-.142.614,1.3,1.3,0,0,0,.316.867l7.648,8.5V13.643L2.67,22.156a1.3,1.3,0,0,0-.316.867,1.323,1.323,0,0,0,.142.614,1.074,1.074,0,0,0,.38.427.993.993,0,0,0,.543.151.966.966,0,0,0,.406-.084A1.1,1.1,0,0,0,4.168,23.878Z"
            transform="translate(1070.646 473.785)" :fill="color"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconCircleNext",
  data() {
    return {
      color: '#FFF'
    }
  },
}
</script>

<style lang="scss" scoped>
svg {
  width: 30px;
}
</style>
<template>
  <div id="video-player">
    <video class="video-js vjs-big-play-centered vjs-show-big-play-button-on-pause"
           :data-setup='JSON.stringify(Object.assign(dataSetup, {"poster": item.thumbnail}))'
           @play="playing"
           @ended="ended"
           @pause="pause"
           ref="videoJsPlayer"
           :key="item.id"
    >
      <source :src="item.file"/>
    </video>
    <IconBack v-if="showBackButton" class="icon-back" @click="leave"/>
  </div>
</template>

<script>
import videojs from 'video.js';
import IconBack from "@/components/common/icons/IconBack";
import {
  inject,
  onMounted,
  onUnmounted,
  ref,
} from "vue";

export default {
  name: "VideoPlayer",
  components: {IconBack},
  props: {
    item: {
      type: Object,
      required: true,
    },
    allowFullscreen: {
      type: Boolean,
      default: false
    },
    autoPlay: {
      type: Boolean,
      default: false,
    },
    showBackButton: {
      type: Boolean,
      default: false
    }
  },

  setup(props) {
    const emitter = inject('$BUS')
    const videoJsPlayer = ref(null)
    const player = ref(null)

    onMounted(() => {
      player.value = videojs(videoJsPlayer.value, dataSetup.value, function onPlayerReady() {
        console.log('Player ready')
      })
    })

    const dataSetup = ref({
      fluid: true,
      controls: true,
      autoplay: props.autoPlay,
      poster: null,
      controlBar: {
        children: [
          "currentTimeDisplay",
          "durationDisplay",
          "playToggle",
          "progressControl",
          "remainingTimeDisplay",
          "timeDivider",
          "volumePanel",
          (props.allowFullscreen) ? "fullscreenToggle" : ""
        ]
      }
    })

    onUnmounted(() => {
      player.value.dispose()
    })

    emitter.on('close-modal', () => {
      if (!player.value.isDisposed_) {
        player.value.pause()
      }
    })

    emitter.on('change-slide', () => {
      if (!player.value.isDisposed_) {
        player.value.pause()
      }
    })

    const playing = () => {
      emitter.emit('playing')
    }

    const pause = () => {
      if (!player.value.isDisposed_) {
        player.value.pause()
      }
      emitter.emit('pause')
    }

    const leave = () => {
      emitter.emit('close-video')
    }

    const ended = () => {
      if (!player.value.isDisposed_) {
        player.value.currentTime(0)
        player.value.hasStarted(false)
      }
      emitter.emit('ended')
    }


    return {
      dataSetup,
      player,
      videoJsPlayer,
      ended,
      leave,
      pause,
      playing,
    }
  },
}
</script>

<style src="video.js/dist/video-js.css"/>

<style lang="scss">

#video-player {

  .icon-back {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  .video-js {
    height: 100vh;

    .vjs-big-play-button {
      border-radius: 50%;
      //color: #C00;
      height: 50px;
      width: 50px;
    }

    &:hover .vjs-big-play-button,
    &:focus .vjs-big-play-button {
      border-color: #C00;
      color: #C00;
    }

    .vjs-play-progress {
      background-color: #C00;
      color: #C00;
    }

    .vjs-hidden {
      visibility: hidden;
    }
  }

  position: relative;
}
</style>
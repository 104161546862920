<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
    <g id="Icon_feather-plus-circle" data-name="Icon feather-plus-circle" transform="translate(-2.5 -2.5)">
      <path id="Path_2193" data-name="Path 2193" d="M15,9A6,6,0,1,1,9,3a6,6,0,0,1,6,6Z" transform="translate(0 0)"
            fill="none" :stroke="color" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
      <path id="Path_2194" data-name="Path 2194" d="M18,12v6" transform="translate(-9 -6)" fill="none" :stroke="color"
            stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
      <path id="Path_2195" data-name="Path 2195" d="M12,18h6" transform="translate(-6 -9)" fill="none" :stroke="color"
            stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconPlusCircle",
  props: {
    color: {
      type: String,
      default: '#FFF'
    }
  }
}
</script>
<template>
  <div class="button">
    <div class="before">
      <IconPlusCircle/>
    </div>
    <div class="text">{{ text }}</div>
  </div>
</template>

<script>
import IconPlusCircle from "@/components/common/icons/IconPlusCircle";

export default {
  name: "base-button",
  components: {IconPlusCircle},
  props: {text: {type: String}}
}
</script>

<style lang="scss" scoped>
.button {
  background-color: #C00;
  cursor: pointer;
  display: inline-grid;
  grid-template-columns: 40px auto;
  height: 40px;

  .before {
    align-items: center;
    background-color: #A00;
    display: flex;
    height: inherit;
    justify-content: center;
  }

  .text {
    align-items: center;
    color: #FFF;
    display: flex;
    font-size: 14px;
    height: inherit;
    justify-content: flex-start;
    padding: 12px;
    transition: all .3s ease-in-out;
  }

  &:hover {
    .text {
      background-color: #AA0000;
      transition: all .3s ease-in-out;
    }
  }
}
</style>
<template>
<svg id="BackButton" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
  <rect id="Rectangle_11" data-name="Rectangle 11" width="25" height="25" :fill="background"/>
  <path id="Arrow" d="M6.446,14.046,2.533,9.675A.676.676,0,0,1,2.4,8.968a.747.747,0,0,1,.132-.214L6.452,4.389a.474.474,0,0,1,.369-.175.482.482,0,0,1,.272.078.56.56,0,0,1,.19.217.661.661,0,0,1,.071.307A.65.65,0,0,1,7.2,5.25L3.371,9.5V8.929L7.2,13.185a.65.65,0,0,1,.158.433.661.661,0,0,1-.071.307.537.537,0,0,1-.19.214.5.5,0,0,1-.272.075.483.483,0,0,1-.2-.042A.548.548,0,0,1,6.446,14.046Z" transform="translate(7.646 3.785)" :fill="color"/>
</svg>
</template>

<script>
export default {
  name: "IconBack",
  props: {
    background: {
      type: String,
      default: '#C00'
    },
    color: {
      type: String,
      default: '#FFF'
    }
  }
}
</script>

<style lang="scss" scoped>
  svg {
    cursor: pointer;
  }
</style>
<template>
  <div class="single-pdf h-100 w-100">
      <iframe :src="data.item_details[0].file" class="pdf w-100 h-100" />
  </div>
</template>

<script>
export default {
  name: "single-pdf",
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.single-pdf {

}
</style>
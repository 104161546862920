<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" @mouseover="color = '#fff'" @mouseout="color = '#999'">
    <g id="Path_2455" data-name="Path 2455" transform="translate(-7422.879 -2164.308)" fill="none"
       stroke-linecap="round" stroke-linejoin="round">
      <path d="M7424.879,2182.308l12-7.531-3.471-2.449-8.529-6.019Z" stroke="none"/>
      <path
          d="M 7424.87890625 2182.3076171875 L 7436.87890625 2174.776123046875 L 7424.87890625 2166.3076171875 L 7424.87890625 2182.3076171875 M 7424.87890625 2184.3076171875 C 7424.5458984375 2184.3076171875 7424.212890625 2184.224609375 7423.9111328125 2184.057861328125 C 7423.2744140625 2183.70556640625 7422.87890625 2183.035400390625 7422.87890625 2182.3076171875 L 7422.87890625 2166.3076171875 C 7422.87890625 2165.560546875 7423.29541015625 2164.875732421875 7423.95849609375 2164.531982421875 C 7424.6220703125 2164.1884765625 7425.421875 2164.242431640625 7426.0322265625 2164.673583984375 L 7438.0322265625 2173.14208984375 C 7438.57861328125 2173.52783203125 7438.896484375 2174.161376953125 7438.87841796875 2174.830078125 C 7438.85986328125 2175.4990234375 7438.5087890625 2176.114501953125 7437.94189453125 2176.47021484375 L 7425.94189453125 2184.001708984375 C 7425.61767578125 2184.205078125 7425.24853515625 2184.3076171875 7424.87890625 2184.3076171875 Z"
          stroke="none" :fill="color"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconPlay",
  data() {
    return {
      color: '#999'
    }
  }
}
</script>

<style lang="scss" scoped>

</style>